import React from 'react';
import Layout from '../../templates/layout';
import SignIn from '../../components/user/sign-in.jsx';
import Seo from '../../components/utils/Seo';

const SignInPage = () => (
  <Layout>
    <SignIn />
  </Layout>
);

export const Head = ({ location }) => (
  <Seo location={location} title='Logowanie' description='Zaloguj się do Zapłatomatu aby zadządzać płatnościami i operacjami na swoim koncie'/>
);

export default SignInPage;
