import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {Button} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { styled } from '@mui/material/styles';


const StyledButton = styled(Button)(({theme}) => ({
	backgroundColor: '#3b5998',
	color: 'white',
	"&:hover": {
		background: 'blue'
	}
}));

function mapStateToProps(state) {
	return {
		facebook: state.facebookAction,
	};
}

class FacebookButton extends Component {
	componentDidUpdate(prevProps) {
    if(prevProps.facebook !== this.props.facebook){
			let fb = this.props.facebook;
      if(fb.url){
				window.location.href = fb.url;
			}
    }
	}

	handleFacebookButton() {
		this.props.actions.facebookAction({type: this.props.type});
	}


	render() {
		return (
			<StyledButton fullWidth
				disableElevation
        variant="contained"
        endIcon={<FacebookIcon />}
				onClick={this.handleFacebookButton.bind(this)}
			>
        {this.props.content}
      </StyledButton>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton)
