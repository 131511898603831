import BaseComponent from './BaseComponent';

export default class FormComponent extends BaseComponent {
  constructor(props){
    super(props);
    this.state = {
      formErrorsMessage: '',
      formErrors: {},
      formErrorsKeys: {},
      showErrors: false,
      disabled: false,
      loading: true
    }
  }

  validateResponse(p, onSuccess = () => {}) {
    if (p.details || p.errors) {
      let receivedErrors = p.details ? p.details : p.errors;
      let errors = {};
      let errorKeys = {};
      for (let key in receivedErrors) {
        errors[key] = receivedErrors[key];
        errorKeys[key] = true;
      }
      let data = {
        showErrors: true,
        disabled: false,
        loading: false,
        formErrors: errors,
        formErrorsKeys: errorKeys,
        formErrorsMessage: p.message ? p.message : 'Error'
      };
      this.setState(data);
    } else if(p.error || p.reason){
      let message = p.error ? p.message : p.reason;
      this.setState({
        showErrors: true,
        disabled: false,
        loading: false,
        formErrors: [message],
        formErrorsKeys: [],
        formErrorsMessage: p.error
      });
      if(this.props.actions) this.props.actions.setSnackbarErrorNotification(message);
    } else {
      onSuccess();
    }
  }

  handleSubmitForm(callback, e) {
    e.preventDefault();
    this.setState(
      {
        formErrors: [],
        formErrorsKeys: [],
        showErrors: false,
        disabled: true,
        loading: true
      },
      callback
    );
  }
}
