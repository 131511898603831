import React from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Link, { navigate } from 'gatsby-link';
import FormComponent from '../containers/FormComponent';
import FacebookButton from '../utils/FacebookButton';
import { styled } from '@mui/material/styles';
import { SHOW_FACEBOOK } from '../../constants';

function mapStateToProps(state) {
  return {
    login: state.login,
    loginRequired: state.loginRequired,
  };
}

const PREFIX = 'SignIn';
const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};
const RootContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const REDIRECT_TO = '/payment';

const MIN_PASSWORD_LENGTH = 6;

class SignIn extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      email: '',
      password: '',
      emailError: false,
      emailErrorMessage: '',
      disabled: true,
      passDisabled: true,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate(REDIRECT_TO);
    } else {
      setTimeout(() => this.setState({ loading: false }), 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { login, loginRequired } = this.props;

    if (
      this.props.login !== prevProps.login &&
      this.props.login?.loading === false
    ) {
      const passDisabled = this.state.password.length < MIN_PASSWORD_LENGTH;
      this.setState({loading: false, disabled: false, passDisabled});
    }

    if (loginRequired !== prevProps.loginRequired) {
      this.validateResponse(loginRequired);
    }

    if (login !== prevProps.login) {
      this.validateResponse(login, () => {
        if (login.access_token) {
          const token = 'Bearer ' + login.access_token;
          localStorage.setItem('authToken', token);
          localStorage.setItem('refreshToken', login.refresh_token);
          this.props.actions.getUserSilent();
          navigate(REDIRECT_TO);
        }
      });
    }

    if (prevState.email !== this.state.email) {
      const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.state.email) {
        this.setState({
          emailErrorMessage: 'Podaj email',
          disabled: true,
        });
      } else if (!regExp.test(this.state.email)) {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Nieprawidłowy format email',
          disabled: true,
        });
      } else {
        this.setState({
          emailError: false,
          emailErrorMessage: '',
          disabled: false,
        });
      }
    }
    if (prevState.password !== this.state.password) {
      if (this.state.password.length < MIN_PASSWORD_LENGTH) {
        this.setState({
          passwordError: true,
          passDisabled: true,
          passwordErrorMessage: 'Hasło jest za krótkie',
        });
      } else {
        this.setState({
          passwordError: false,
          passwordErrorMessage: '',
          passDisabled: false,
        });
      }
    }
  }

  isBtnDisabled = () => {
    const { passDisabled, disabled } = this.state;
    if (passDisabled || disabled) {
      return true;
    }
  };

  callLogin() {
    let data = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.actions.login(data);
  }

  handleFocus(e) {
    e.target.select();
  }

  content() {
    const {
      loading,
      email,
      emailErrorMessage,
      emailError,
      formErrorsKeys,
      formErrors,
      password,
      passwordError,
      passwordErrorMessage,
    } = this.state;

    if (loading) return <CircularProgress />;

    return (
      <>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Logowanie
        </Typography>
        <form onSubmit={this.handleSubmitForm.bind(this, this.callLogin)}>
          <TextField
            autoComplete='username'
            variant='outlined'
            label='email'
            name='email'
            id='email'
            type='email'
            placeholder='Email'
            value={email}
            onChange={this.handleValueChange.bind(this)}
            required
            fullWidth
            inputProps={{ maxLength: 255 }}
            onFocus={this.handleFocus.bind(this)}
            margin={'normal'}
            error={emailError || formErrorsKeys.email}
            helperText={emailErrorMessage || formErrors.email}
          />
          <TextField
            variant='outlined'
            required
            autoComplete='current-password'
            label='hasło'
            name='password'
            id='current-password'
            type='password'
            value={password}
            fullWidth
            margin='normal'
            onChange={this.handleValueChange.bind(this)}
            inputProps={{ maxLength: 32 }}
            error={passwordError || formErrorsKeys.password}
            helperText={passwordErrorMessage || formErrors.password}
          />
          <Button
            fullWidth
            disableElevation
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={this.isBtnDisabled()}
            type='submit'
          >
            Zaloguj
          </Button>
          {SHOW_FACEBOOK && <Box py={2}>
            <FacebookButton
              type='login'
              content={'Zaloguj się przez Facebook'}
            />
          </Box>}
          <Grid container>
            <Grid item xs>
              <Link
                className='link-inline'
                to='/user/reset-password'
                variant='body2'
              >
                Zapomniałeś hasła?
              </Link>
            </Grid>
            <Grid item>
              Nie masz konta?{' '}
              <Link className='link-inline' to='/user/register' variant='body2'>
                Zarejestruj się.
              </Link>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  render() {
    return (
      <RootContainer component='main' maxWidth='xs'>
        <div className={classes.paper}>{this.content()}</div>
      </RootContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
